module.exports = [{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":":/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rujuta Phadke Intellectual Property","short_name":"RPIP","start_url":"/","background_color":"#ffffff","theme_color":"#FFDE59","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2bf618cb0d2ff79d9098748f54aab383"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
